import React, { useState, useEffect, lazy, Suspense } from "react";
import { Seo } from "../components";
const Layout = lazy(async () => await import("../components/layout/layout"))
const Main = lazy(async () => await import("../components/main/main"));

const Index = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) return null;
  return (
    <Seo schema={BusinessSchema}>
      <Suspense fallback={<div></div>}>
        <Layout>
          <Main />
        </Layout>
      </Suspense>
    </Seo>
  );
};
export default Index;

const BusinessSchema = JSON.stringify({
  "@context": "http://schema.org",
  "@type": "LocalBusiness",
  name: "Kenora Craft Wine and Beer",
  image:
    "https://www.datocms-assets.com/44515/1616502058-logowhite.png?fm=webp",
  telephone: "(807)467-8581",
  address: {
    "@type": "PostalAddress",
    streetAddress: "608 B, 4th Street",
    addressLocality: "Keewatin",
    addressRegion: "Ontario",
    postalCode: "P0X1C0",
  },
});
